import { Link } from "gatsby";
import React from "react";
import { Alert, Col, Row } from "react-bootstrap";
import HelpBase from "../../components/help-base";
import { BRAND_NAME } from "../../util/constants";

const SupportedHardware = () => (
  <HelpBase title={`Supported Hardware on ${BRAND_NAME}`}>
    <Row>
      <Col>
        <p className="lead">
          Unlike other options, {`${BRAND_NAME} `} does not require you to
          purchase custom or expensive hardware from us. Instead,{" "}
          {`${BRAND_NAME} `} works with great off-the-shelf hardware.
        </p>
        <hr />
      </Col>
    </Row>
    <Row>
      <Col>
        <h3>Compatible Playback Devices</h3>
        <p>
          {`${BRAND_NAME} `} works best with the current generation of Amazon
          Fire TV devices.
        </p>
        <ul>
          <li>
            <b>
              <a href="https://amzn.to/3hC4xqr" target="_new">
                Fire TV Stick
              </a>
            </b>
            : The best, lowest-cost option. Often available on sale for $20-$30.
          </li>
          <li>
            <b>
              <a href="https://amzn.to/3qyNSYM" target="_new">
                Fire TV Stick 4K
              </a>
            </b>
            : An outstanding choice for high-quality displays.
          </li>
          <li>
            <b>
              <a href="https://amzn.to/3h6TvKt" target="_new">
                Fire TV Stick Basic Edition (International Version)
              </a>
            </b>
            : For non-US customers, the International Edition is a great choice.
          </li>
        </ul>
        <p>
          Once you have one of these devices, simply install the{" "}
          <a href="http://amzn.to/1UjmjMI">{`${BRAND_NAME} `} App</a> and you're
          good to go! See our{" "}
          <Link to={`/help/fire-tv-setup`}>full Fire TV setup guide</Link>
          for detailed instructions.
        </p>
      </Col>
    </Row>
    <Row>
      <Col>
        <h3>Compatible TVs</h3>
        <p>
          {`${BRAND_NAME} `} works great with 1080p-capable TV with a spare HDMI
          input. We recommend you test your TV's compatibility prior to any
          permanent hardware installation or modification.
        </p>
        <Alert variant="info">
          <b>Important:</b> Many TVs advertise "smart" capabilities, like having
          a built-in App Store or browser. Because we cannot test or guarantee
          compatibility with these systems, you will need to use a Fire TV stick
          as described above even if your TV is "smart".
        </Alert>
      </Col>
    </Row>
  </HelpBase>
);

export default SupportedHardware;
