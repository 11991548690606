import React from "react";
import { Col, Row, Nav } from "react-bootstrap";
import Layout from "./layout";
import SEO from "./seo";
import { Link } from "gatsby";
import { HELPCENTER_FAQ_URL } from "../util/constants";

import './help-base.scss';

const HelpBase = ({ children, title, pageTitle = null }) => (
  <Layout>
    <SEO title={title} />
    <section className="section" style={{ paddingTop: 30 }}>
      <Row>
        <Col md={3}>
          <Nav fill variant="pills" defaultActiveKey="/home" className="flex-column">
            <Nav.Item>
              <Nav.Link as={Link} activeClassName="active" to="/help">Help Center Home</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} activeClassName="active" to="/hardware">Hardware Help</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} activeClassName="active" to="/hardware/fire-tv">Fire TV Setup</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} activeClassName="active" to="/hardware/apple-tv">Apple TV Setup</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href={HELPCENTER_FAQ_URL}>FAQ</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col>
          <h1>{pageTitle || title}</h1>
          {children}
        </Col>
      </Row>
    </section>
  </Layout>
);

export default HelpBase;
